import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Heading } from 'common/typography';
import { configureImageSrc } from 'services';

import { HighlightContainer, HighlightBlock, HighlightDescription, ReadMore } from './styled';

export const HighlightLinks: React.FC<ContentProps> = ({ content }) => {
  const { t } = useTranslation();
  if (!content) return null;

  const leftImage = content?.leftBlockBackgroundImage?.file?.url;
  const rightImage = content?.rightBlockBackgroundImage?.file?.url;

  return (
    <HighlightContainer>
      <HighlightBlock $imageUrl={configureImageSrc(leftImage)} to={`/${content.leftCtaTarget?.slug}`}>
        <Heading as="h3" isCentered margin="0 0 5px" upperCase weight={700}>{content?.leftBlockHeading}</Heading>
        <HighlightDescription variant="white">
          {content?.leftBlockDescription}
        </HighlightDescription>
        <ReadMore>
          {t('read more')}
        </ReadMore>
      </HighlightBlock>
      <HighlightBlock $imageUrl={configureImageSrc(rightImage)} to={`/${content.rightCtaTarget?.slug}`}>
        <Heading as="h3" isCentered margin="0 0 5px" upperCase weight={700}>{content?.rightBlockHeading}</Heading>
        <HighlightDescription variant="white">
          {content?.rightBlockDescription}
        </HighlightDescription>
        <ReadMore>
          {t('read more')}
        </ReadMore>
      </HighlightBlock>
    </HighlightContainer>
  );
};

export const query = graphql`
  fragment HighlightImageLinksFragment on ContentfulComponentHighlightImageLinks {
    id
    leftBlockBackgroundImage {
      file {
        url
      }
    }
    leftBlockDescription
    leftBlockHeading
    leftCtaTarget {
      slug
    }
    leftCtaText
    rightBlockBackgroundImage {
      file {
        url
      }
    }
    rightBlockDescription
    rightBlockHeading
    rightCtaTarget {
      slug
    }
    rightCtaText
  }
`;

type ContentProps = {
  content: i.ComponentHighLightImageLinks;
}
