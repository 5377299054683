import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { ContentContainer } from 'common/layout';
import { Heading, Text } from 'common/typography';

import { ColumnContainer } from './styled';

export const TextColumns: React.FC<TextColumnsProps> = ({ content }) => {
  const columnItems: { title: string; text: string; }[] = [];
  [...new Array(6)].forEach((_, index) => {
    const key = index + 1;
    //@ts-ignore - ts doens't get the object nesting with matching keys
    const itemText = content[`columnText${key}`]?.[`columnText${key}`];
    const itemTitle = content[`columnTitle${key}` as keyof i.ComponentTextColumns];
    if (itemTitle) {
      columnItems.push({
        title: itemTitle as string,
        text: itemText,
      });
    }
  });

  return (
    <ContentContainer>
      <ColumnContainer>
        {columnItems.map((item) => (
          <div key={item.title}>
            <Heading as="h3" variant="black" weight={700} upperCase margin="0 0 8px">
              {item.title}
            </Heading>
            <Text variant="gray" $size={16} $lineHeight="24">{item.text}</Text>
          </div>
        ))}
      </ColumnContainer>
    </ContentContainer>
  );
};

export const query = graphql`
  fragment TextColumnsFragment on ContentfulComponentTextColumns {
    title
    columnTitle1
    columnTitle2
    columnTitle3
    columnTitle4
    columnTitle5
    columnTitle6
    columnText1 { columnText1 }
    columnText2 { columnText2 }
    columnText3 { columnText3 }
    columnText4 { columnText4 }
    columnText5 { columnText5 }
    columnText6 { columnText6 }
  }
`;

type TextColumnsProps = {
  content: i.ComponentTextColumns;
};
