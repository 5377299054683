import * as i from 'types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { graphql } from 'gatsby';

import { Input } from 'common/form';
import { Heading } from 'common/typography';
import { postForm } from 'ducks/forms';
import { useDispatch } from 'services/hooks';
import { configureImageSrc, validation } from 'services';

import {
  FormBlockContainer, TitleContainer, FormContentContainer, FormContent, StyledForm,
  Column, StyledButton,
} from './styled';

export const FormBlock: React.FC<FormBlockProps> = ({ content }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      isValid,
      errors,
    },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data: i.ComponentFormBlockData) => {
    dispatch(postForm(data, content?.subject))
      .then(() => {
        reset();
        alert(content.successmessage);
      }).catch(() => {
        alert('something went wrong, please try again later');
      });
  };

  const isRequired = (required: boolean) => {
    if (required) {
      return { ...validation.required };
    } else return undefined;
  };

  return (
    <FormBlockContainer>
      <TitleContainer src={configureImageSrc(content?.image?.file?.url)}>
        <Heading as="h3" upperCase weight={700}>{content.title}</Heading>
      </TitleContainer>
      <FormContentContainer>
        <FormContent>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Column>
              <Input
                {...register('name', { ...validation.required })}
                label={content?.titleFirstInputField}
                error={errors?.name}
                variant="white"
              />
              <Input
                {...register('emailaddress', { ...validation.required, ...validation.email })}
                label={content?.titleSecondInputField}
                error={errors?.emailaddress}
                variant="white"
              />
              <Input
                {...register('thirdInput', isRequired(content.thirdInputFieldRequired))}
                label={content?.titleThirdInputField}
                error={errors?.thirdInput}
                variant="white"
              />
            </Column>
            <Column $flexEnd>
              <Input
                {...register('textareaInput', { ...validation.required })}
                label={content?.titleTextareainput}
                error={errors?.textareaInput}
                type="textarea"
                as="textarea"
                variant="white"
              />
            </Column>
            <StyledButton
              variant="CTA"
              type="submit"
              $isValid={isValid}
            >
              I'm ready
            </StyledButton>
          </StyledForm>
        </FormContent>
      </FormContentContainer>
    </FormBlockContainer>
  );
};

export const query = graphql`
  fragment FormBlockFragment on ContentfulComponentForm {
    __typename
    id
    image {
      file {
        url
      }
    }
    title
    id
    successmessage
    subject
    title
    titleThirdInputField
    titleTextareainput
    titleSecondInputField
    titleFirstInputField
    thirdInputFieldRequired
  }
`;

type FormBlockProps = {
  content: i.ComponentFormBlock;
};
