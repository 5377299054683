import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ContentContainer = styled.div<ContentProps>`
  display: flex;
  max-width: 1152px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px 60px;

  ${({ $align }) => $align === 'left' && css`
    justify-content: flex-start;
  `}

  ${({ $align }) => $align === 'center' && css`
    justify-content: center;
  `}

  ${({ $align }) => $align === 'right' && css`
    justify-content: flex-end;
  `}

  ${media.desktop`
    padding: 0 24px 90px;
  `}

  ${({ noPadding }) => noPadding && css`
    padding: 0;
    justify-content: flex-start;

    ${media.desktop`
      padding: 0;
    `}
  `}
`;

type ContentProps = {
  $align: string;
  noPadding?: boolean;
};
