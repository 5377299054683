import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';
import { Navigation } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';

import { Heading, Text } from 'common/typography';
import { CarouselNavigation } from 'common/interaction';
import { ContentContainer } from 'common/layout';
import { configureImageSrc } from 'services';

import {
  CarouselContainer, CarouselSlide, CarouselImage, CarouselContent,
} from './styled';

export const ReviewCarousel: React.FC<ReviewCarouselProps> = ({ content }) => {
  const swiperParams = {
    className: 'carousel',
    centeredSlides: true,
    grabCursor: true,
    loop: true,
    spaceBetween: 24,
    navigation: {
      navigation: true,
      prevEl: '.carousel__prev',
      nextEl: '.carousel__next',
    },
    breakpoints: {
      300: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <ContentContainer>
      <CarouselContainer>
        <Swiper
          modules={[Navigation]}
          {...swiperParams}
        >
          {/* when adding the same items to the page, id's are identical. That is why index is used here */}
          {content.reviews?.map((el, index) => (
            <SwiperSlide key={index} className="carousel__slide">
              <CarouselSlide>
                <CarouselImage imageUrl={configureImageSrc(el.image?.file?.url)} />
                <CarouselContent>
                  <Text variant="gray" $size={16}>{el.subtitle}</Text>
                  <Heading
                    as="h3"
                    variant="black"
                    isCentered
                    margin="8px 0 24px"
                    upperCase
                    weight={700}
                  >
                    {el.name}
                  </Heading>
                  <Text variant={['gray', 'dark']} $size={16} $lineHeight="24">
                    {el.quote.quote}
                  </Text>
                </CarouselContent>
              </CarouselSlide>
            </SwiperSlide>
          ))}
          <CarouselNavigation />
        </Swiper>
      </CarouselContainer>
    </ContentContainer>
  );
};

export const query = graphql`
  fragment ReviewCarouselFragment on ContentfulComponentReviewCarousel {
    id
    title
    reviews {
      name
      subtitle
      quote {
        quote
      }
      image {
        file {
          url
        }
      }
    }
  }
`;

type ReviewCarouselProps = {
  content: i.ComponentReviewCarousel;
};
