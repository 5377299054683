import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { TextContent } from 'common/typography';

import { Video, Container } from './styled';

export const VideoBlock: React.FC<VideoBlockProps> = ({ content }) => {
  const videoUrl = content.video.file.url;

  return (
    <Container>
      {content?.description?.raw && (
        <TextContent content={content.description.raw} />
      )}
      <Video controls>
        <source src={videoUrl} type="video/mp4" />
      </Video>
    </Container>
  );
};

export const query = graphql`
  fragment VideoblockFragment on ContentfulComponentVideoBlock {
    title
    video {
      file {
        url
      }
    }
    description {
      raw
    }
  }
`;

type VideoBlockProps = {
  content: i.ComponentVideoBlock;
};

