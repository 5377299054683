import styled from 'styled-components';

import { media } from 'styles/utils';

export const PageHeaderContainer = styled.div<PageHeaderContainerProps>`
  height: 100vh;
  width: 100%;
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-position: center;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 60px;
`;

type PageHeaderContainerProps = {
  imageUrl?: string;
};

export const PageHeaderContent = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;

  button {
    align-self: center;
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 36px;

  ${media.desktop`
    top: 30px;
  `}
`;

export const VideoContainer = styled.video`
  position: absolute;
  left: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  transform: translateX(-50%);
  overflow: hidden;
  box-sizing: content-box;
`;
