import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { useDevice } from 'services/hooks';
import { Button } from 'common/interaction';
import { Headline1, Heading } from 'common/typography';
import { VideoModal } from 'common/layout';
import { Logo, Headline1Variant } from 'common/general';
import { configureImageSrc } from 'services';

import { PageHeaderContainer, PageHeaderContent, LogoWrapper, VideoContainer } from './styled';

export const PageHeader: React.FC<ContentProps> = ({ content }) => {
  const { isMobile } = useDevice();
  const backgroundMedia = content?.backgroundMedia?.file;
  const backgroundMediaMobile = content?.backgroundMediaMobile?.file;
  const determinedMediaSource = isMobile ? backgroundMediaMobile : backgroundMedia;
  const [isVideoOpen, setIsVideoOpen] = React.useState(false);

  const isImage = (contentType: string) => contentType.includes('image');
  const isVideo = (contentType: string) => contentType.includes('video');

  const pageImageUrl = isImage(determinedMediaSource?.contentType || '') ? (
    determinedMediaSource?.url
  ) : '';

  return (
    <PageHeaderContainer
      imageUrl={configureImageSrc(pageImageUrl, 1920)}
    >
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {isVideo(determinedMediaSource?.contentType || '') && (
        <VideoContainer autoPlay muted loop playsInline>
          <source src={`${backgroundMedia?.url}`} type={`${backgroundMedia.contentType}`} />
        </VideoContainer>
      )}
      <PageHeaderContent $isCentered={content?.centered || true}>
        <Headline1
          {...Headline1Variant}
          margin="0 0 16px"
          isCentered
        >
          {content?.title}
        </Headline1>
        <Heading as="h3" upperCase margin="0 0 60px" isCentered>{content?.subtitle}</Heading>
        {content?.ctaButtonText && content?.ctaTarget && (
          <Button to={`/${content.ctaTarget}`} variant="CTA">
            {content?.ctaButtonText}
          </Button>
        )}
      </PageHeaderContent>
      {isVideoOpen && (
        <VideoModal
          isOpen
          onClose={() => setIsVideoOpen(false)}
          videoUrl={backgroundMedia?.url}
        />
      )}
    </PageHeaderContainer>
  );
};

export const query = graphql`
  fragment HeroHeaderFullHeightFragment on ContentfulComponentHeroHeaderFullHeight {
    id
    centered
    title
    subtitle
    ctaButtonText
    ctaTarget
    backgroundMediaMobile {
      id
      description
      file {
        url
        contentType
      }
    }
    backgroundMedia {
      id
      description
      file {
        url
        contentType
      }
    }
  }
`;

type ContentProps = {
  content: i.ComponentHeroHeaderFullHeight;
};
