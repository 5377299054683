import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { useDevice } from 'services/hooks';
import { Button } from 'common/interaction';
import { HalfHeader } from 'common/layout';
import { configureImageSrc } from 'services';

export const PageHeaderHalfHeight: React.FC<ContentProps> = ({ content }) => {
  const { isMobile } = useDevice();
  const backgroundImage = content.backgroundMedia.file;
  const backgroundImageMobile = content?.backgroundMediaMobile?.file || content.backgroundMedia.file;
  const determinedMediaSource = isMobile ? backgroundImageMobile : backgroundImage;

  return (
    <HalfHeader
      title={content.subtitle}
      subtitle={content.title}
      imageUrl={configureImageSrc(determinedMediaSource?.url, 1920)}
    >
      {content?.ctaTarget && content?.ctaButtonText && (
        <Button variant="CTA" to={`/${content.ctaTarget}`}>
          {content.ctaButtonText}
        </Button>
      )}
    </HalfHeader>
  );
};

export const query = graphql`
  fragment HeroHeaderHalfHeightFragment on ContentfulComponentHeroHeaderHalfHeight {
    id
    title
    subtitle
    ctaButtonText
    ctaTarget
    backgroundMediaMobile {
      id
      description
      file {
        url
        contentType
      }
    }
    backgroundMedia {
      id
      description
      file {
        url
        contentType
      }
    }
  }
`;

type ContentProps = {
  content: i.ComponentHeroHeaderHalfHeight;
};
