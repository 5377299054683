import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { ContentContainer } from 'common/layout';
import { getSeries } from 'ducks/series';
import { getSites, sitesActions } from 'ducks/sites';
import { getLocalData } from 'services';
import { selectActiveSiteData } from 'selectors/sites';
import {
  ClassesBlock, CheckoutModal, MembershipsBlock, MembershipsBlockMobile,
  CheckoutProvider,
} from 'modules/PricingPage';
import {
  useAuthenticationUser, useDispatch, useSelector, useDevice,
} from 'services/hooks';

export const PricingBlock: React.FC<PricingBlockProps> = ({ content }) => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { determined } = useAuthenticationUser();
  const activeSite = useSelector(selectActiveSiteData);

  const fetchPricingData = async () => {
    if (!activeSite) {
      const localSite = getLocalData('user::activeSite');
      const sites = await dispatch(getSites());
      dispatch(sitesActions.setActiveSite(localSite || sites[0]?.city));
    } else {
      dispatch(getSeries(activeSite));
    }
  };

  React.useEffect(() => {
    if (determined) {
      fetchPricingData();
    }
  }, [determined, activeSite]);

  return (
    <ContentContainer>
      <CheckoutProvider>
        {content.showWorkout ? (
          <ClassesBlock isContentBlock />
        ) : isMobile ? (
          <MembershipsBlockMobile isContentBlock />
        ) : (
          <MembershipsBlock isContentBlock />
        )}
        <CheckoutModal />
      </CheckoutProvider>
    </ContentContainer>
  );
};

export const query = graphql`
  fragment PricingBlockFragment on ContentfulComponentPricingBlock {
    title
    showWorkout
  }
`;

type PricingBlockProps = {
  content: i.ComponentPricingBlock;
};
